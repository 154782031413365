import React from "react";

import Accent from "../Accent";
import LinkButton from "../LinkButton";

import styles from "./styles.module.less";

const CopyBlock = ({
    icon,
    title,
    description,
    flexD,
    buttonTitle,
    name,
    href,
    alt,
}) => {
    return (
        <section className={styles.CopyBlock}>
            <div
                className={styles.container}
                style={{ flexDirection: flexD }}
                name={name}>
                <div className={styles.image}>
                    <img className={styles.icon} src={icon} alt={alt} />
                </div>
                <div className={styles.text}>
                    <h1 className={styles.title}>{title}</h1>
                    <Accent align="left" />
                    <div className={styles.description}>{description}</div>
                    <div className={styles.copyButton}>
                        <LinkButton label={buttonTitle} href={href} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CopyBlock;

CopyBlock.defaultProps = {
    bgColor: "#FFFFFF",
    flexD: "row",
};
