import React from "react";
import HomeCinemaMid from "../../../cinemagraphs/HomeCinemaMid";
import Accent from "../../../Accent";

import { needSpecific } from "../../../../lib/gtm";

import styles from "./styles.module.less";

const NeedSomething = () => {
    const modules = [
        { name: "Adjustable Rate Mortgage", link: "/adjustable-rate-mortgage" },
        { name: "Fixed Rate Mortgage", link: "/fixed-rate-mortgage" },
        { name: "FHA Loan", link: "/fha-loan" },
        { name: "Jumbo Loan", link: "/jumbo-loan" },
        { name: "VA Loan", link: "/va-loans" },
        { name: "USDA Loan", link: "/usda-home-loan" },
    ];

    return (
        <section className={styles.NeedSomething}>
            <HomeCinemaMid />
            <div className={styles.container}>
                <h1 className={styles.title}>Need something specific?</h1>
                <Accent align="left" />
                <div className={styles.moduleContainer}>
                    {modules.map((m, i) => {
                        return (
                            <div key={i} className={styles.blueContainer}>
                                <a
                                    key={i}
                                    href={m.link}
                                    className={styles.module}
                                    data-gtm={needSpecific}>
                                    <div
                                        className={styles.blueBlock}
                                        data-gtm={needSpecific}>
                                        <h2 data-gtm={needSpecific}>
                                            {m.name}
                                        </h2>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};
export default NeedSomething;
