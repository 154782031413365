import React from "react";
import TestimonialsCarousel from "../TestimonialsCarousel";

import styles from "./styles.module.less";

const TestimonialsHome = () => {
    const title = "WHAT PEOPLE ARE SAYING";
    const items = [
        {
            id: "1",
            text:
                "Bay Equity made buying our first home easy. Very informative and helpful throughout the process. Thank you so much!",
            name: "Zoe E",
        },
        {
            id: "2",
            text:
                "Amazing communication, really simple process, super friendly and just as excited for our new home as we were.",
            name: "Cindy C",
        },
        {
            id: "3",
            text:
                "Everyone was kind, friendly and helpful. I would recommend them to anyone who’s looking to buy or sell a home.",
            name: "Heidi E",
        },
        {
            id: "4",
            text:
                "Very professional and the communication was incredible. Thank you!",
            name: "Carl E",
        },
    ];

    if (!items || !items.length) {
        return null;
    }

    return (
        <section className={styles.TestimonialSlider} data-background="photo">
            <div className={styles.inner}>
                <TestimonialsCarousel title={title} items={items} />
            </div>
        </section>
    );
};

export default TestimonialsHome;
