import React, { Component } from "react";
import styles from "./styles.module.less";
import background from "./../../../images/homeHero.jpg";

class HomeCinemaTop extends Component {
    constructor(props) {
        super(props);
        this.backRef = React.createRef();
        this.frontRef = React.createRef();
    }
    componentDidMount() {
        setTimeout(() => {
            if (this.frontRef.current) {
                this.frontRef.current.play();
            }
            return;
        }, 5000);
    }
    render() {
        let frontVideoMp4 =
            "https://ucarecdn.com/a451f11a-8b5e-4e36-80a5-c2e00cfa593a/Tillamook_Facade_light.mp4";
        let backVideoMp4 =
            "https://ucarecdn.com/cce66491-6d4d-4d9e-823c-48d90ad62423/Tillamook_Facade_Trees.mp4";

        return (
            <section className={styles.HomeCinemaTop}>
                <div className={styles.bgVideo}>
                    <video
                        className={styles.back}
                        ref={this.backRef}
                        loop
                        autoPlay
                        muted
                        playsInline>
                        <source src={backVideoMp4} type="video/mp4" />
                        <source src={backVideoMp4} type="video/ogg" />
                        <img
                            src={background}
                            title="Your browser does not support the video tag."
                            alt=""
                        />
                    </video>
                    <div className={styles.shape}>
                        <video
                            className={styles.front}
                            ref={this.frontRef}
                            muted
                            playsInline>
                            <source src={frontVideoMp4} type="video/mp4" />
                            <source src={frontVideoMp4} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>
        );
    }
}

export default HomeCinemaTop;
