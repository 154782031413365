import React, { Component } from "react";

import Accent from "../Accent";

import styles from "./styles.module.less";

import justlooking from "./../../images/justlooking.png";
import buyingagain from "./../../images/buyingagain.png";
import firsttime from "./../../images/firsttime.png";
import refinancing from "./../../images/refinancing.png";

import { whereToStart } from "../../lib/gtm";

export default class Panel extends Component {
    render() {
        return (
            <section className={styles.Panel}>
                <div className={styles.inner}>
                    <div className={styles.panel}>
                        <h1
                            className={styles.title}
                            style={{ fontSize: "25px" }}>
                            Not sure where to start?
                        </h1>
                        <Accent align="left" />
                        <div className={styles.content}>
                            <div className={styles.itemContainer}>
                                <div className={styles.item}>
                                    <a
                                        href="/get-started"
                                        data-gtm={whereToStart}>
                                        <img
                                            src={justlooking}
                                            alt="Looking To Buy A Home - Just Looking Icon | Bay Equity Home Loans"
                                            data-gtm={whereToStart}
                                        />
                                        <h2 data-gtm={whereToStart}>
                                            Just looking?
                                        </h2>
                                    </a>
                                </div>
                                <div className={styles.item}>
                                    <a
                                        href="/first-time-homebuyer"
                                        data-gtm={whereToStart}>
                                        <img
                                            src={firsttime}
                                            alt="Buying Your First Home - First Time Homebuyer Icon | Bay Equity Home Loans"
                                            data-gtm={whereToStart}
                                        />
                                        <h2 data-gtm={whereToStart}>
                                            First-time homebuyer?
                                        </h2>
                                    </a>
                                </div>
                                <div className={styles.item}>
                                    <a
                                        href="/current-homeowner"
                                        data-gtm={whereToStart}>
                                        <img
                                            src={buyingagain}
                                            alt="Buying A Second Home - Buying Again Icon | Bay Equity Home Loans"
                                            data-gtm={whereToStart}
                                        />
                                        <h2 data-gtm={whereToStart}>
                                            Buying again?
                                        </h2>
                                    </a>
                                </div>
                                <div className={styles.item}>
                                    <a
                                        href="/refinance-mortgage"
                                        data-gtm={whereToStart}>
                                        <img
                                            src={refinancing}
                                            alt="Refinancing Your Mortgage - Refinancing Icon | Bay Equity Home Loans"
                                            data-gtm={whereToStart}
                                        />
                                        <h2 data-gtm={whereToStart}>
                                            Refinancing?
                                        </h2>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
