import React from "react";

import HomeCinemaTop from "../cinemagraphs/HomeCinemaTop";
import CopyBlockHome from "../CopyBlockHome";
import Panel from "../Panel";
import Calcs from "../Calculators";
import NeedSomething from "./components/NeedSomething";
import TestimonialsHome from "../TestimonialsHome";
import FindLO from "../FindLO";
import Accent from "../Accent";
import StaticText from "../StaticText";

import AllTheEaseImg from "../../images/man.jpg";
import WereHereToImg from "./../../images/man2.png";

import styles from "./styles.module.less";

const HomePage = ({ isPreview, staticTextItems }) => {
    const AllTheEaseDesc = (
        <>
            We understand the home loan process can be daunting, so we’re here
            to support you all the way through. From a home loan to a home
            refinance, we’re proud to offer a secure application process that
            you can easily complete on your smartphone or computer.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>
                Want to make it personal?
            </span>{" "}
            Contact one of our outstanding loan officers to personally guide
            your journey, step by step.
        </>
    );

    return (
        <section>
            <div>
                <HomeCinemaTop />

                <div className={styles.heroCopy}>
                    <div className={styles.inner}>
                        <div className={styles.homeHero}>
                            <div className={styles.title}>
                                We&#8217;re here
                                <br /> to get you home
                            </div>
                            <Accent align="left" />
                            <div>
                                <StaticText
                                    id="home-header"
                                    items={staticTextItems}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.PanelBlock}>
                <Panel />
            </div>
            <CopyBlockHome
                title="All the ease of online"
                description={AllTheEaseDesc}
                buttonTitle={"Find Your Team"}
                href="/find-your-loan-officer"
                icon={AllTheEaseImg}
                flexD="row-reverse"
                alt="Man Sitting on Couch Working on Laptop - Online Home Loan Process | Bay Equity Home Loans"
            />
            <Calcs
                key="calculators"
                showCalculatorMortgage={true}
                showCalculatorRentVsBuy={true}
                showCalculatorHomeAffordability={true}
                showCalculatorRequiredIncome={true}
                showCalculatorRefinance={true}
                isPreview={isPreview}
                staticTextItems={staticTextItems}
            />
            <NeedSomething />
            <FindLO />
            <CopyBlockHome
                className={styles.wereHome}
                title={
                    <>
                        We&#8217;re here
                        <br /> to get you home
                    </>
                }
                description={
                    <StaticText id="home-were-here" items={staticTextItems} />
                }
                buttonTitle={"Learn More"}
                href="/about-us"
                icon={WereHereToImg}
                flexD="row"
                alt="Man Wearing Bay Equity Hat at the Park - Full-Service Home Mortgage Lender | Bay Equity Home Loans"
            />
            <TestimonialsHome />
        </section>
    );
};

export default HomePage;
